import Roblox from 'Roblox';
import {
  addAssetToAvatar,
  removeAssetFromAvatar,
  removeAssetTypesFromAvatar,
  removeLayeredClothingFromAvatar,
  getAssetTypeById,
  getAssetTypeByName,
  getAssetTypeByType,
  getAssetTypeNameById,
  getLayeredClothingAssetOrder,
  getAdvancedAccessoryLimit,
  isAccessoryType,
  isAnimation,
  isEmote,
  isLayeredClothing,
  isDynamicHead,
  isDynamicHeadInAssetList,
  buildMetaForAsset,
  insertAssetMetaIntoAssetList,
  buildMetaForAssets,
  maxNumberOfLayeredClothingItems,
  isBodyPart,
  layeredClothingRefinementOrderOffset
} from './services/accoutrementRulesService';

Roblox.AvatarAccoutrementService = {
  addAssetToAvatar,
  removeAssetFromAvatar,
  removeAssetTypesFromAvatar,
  removeLayeredClothingFromAvatar,
  getAssetTypeById,
  getAssetTypeByName,
  getAssetTypeByType,
  getAssetTypeNameById,
  getLayeredClothingAssetOrder,
  getAdvancedAccessoryLimit,
  isAccessoryType,
  isAnimation,
  isEmote,
  isLayeredClothing,
  isDynamicHead,
  isDynamicHeadInAssetList,
  buildMetaForAsset,
  insertAssetMetaIntoAssetList,
  buildMetaForAssets,
  maxNumberOfLayeredClothingItems,
  isBodyPart,
  layeredClothingRefinementOrderOffset
};
